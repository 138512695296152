import React from "react";
import {Fade, Slide} from "react-awesome-reveal";
import serimg1 from "../img/ser1.png";
import serimg2 from "../img/ser2.png";

import img1 from "../img/Tools/1.png";
import img2 from "../img/Tools/2.png";
import img3 from "../img/Tools/3.png";
import img4 from "../img/Tools/4.png";
import img5 from "../img/Tools/5.png";
import img6 from "../img/Tools/6.png";
import img7 from "../img/Tools/7.png";
import img8 from "../img/Tools/8.png";


export default function About(){

    const ToolsData = [
        {
            id: 1,
            name: 'Shizuoka',
            img: img1,
            desc: '40x20X20'
        },
        {
            id: 2,
            name: 'Cincinnati',
            img: img2,
            desc: '80X30X30'
        },
        {
            id: 3,
            name: 'Hitachi Seiki',
            desc: '40X20X20',
            img: img3
        },
        {
            id: 4,
            name: 'Doosan',
            desc: '60X30X30',
            img: img4
        },
        {
            id: 5,
            name: 'MORI CL-200',
            desc: '',
            img: img5
        },
        {
            id: 6,
            name: 'Miyano Frontier',
            img: img6
        },
        {
            id: 7,
            name: 'OKUMA LB1',
            img: img7
        },
        {
            id: 8,
            name: 'Mistral CMM',
            img: img8
        },
    ]

    return (
        <main className="">
            <section className=" bg-aboutsec bg-no-repeat bg-center bg-cover w-screen lg:h-[700px] relative">
                <div className=" w-full h-screen lg:h-full bg-black bg-opacity-80">
                    <div className=" flex lg:px-0 px-4 space-y-6 flex-col lg:max-w-screen-lg mx-auto h-full text-white justify-center items-center">
                        <h1 className=" text-main-color lg:text-6xl text-4xl font-header-font">About</h1>
                        <div className=" flex text-center lg:text-2xl text-lg space-y-4 flex-col">
                            <span className="">
                                We are a Silicon Valley based premier contract manufacturer producing quality parts for a wide range of industries. 
                                Our talented and highly experienced operators produce designs and build products for the defense, aerospace, 
                                automotive, medical, and semiconductor industries.
                                Our shop is equipped with leading-edge machinery, tools, and knowledge. 
                            </span>

                            <span className="">
                                We're a full-service shop for our customers. Not only do we produce parts and assemblies, 
                                but we also provide turnkey program integration and assembly services. Customer service is our top priority. 
                                You can expect high quality precision in our work product and on-time delivery.
                            </span>

                            <span className=" lg:block hidden">
                                We have the capacity to produce parts from a wide range of materials. We specialize in aluminum, 
                                brass, copper, titanium, stainless steel, and plastics. We offer high-quality CNC milling, CNC turning, and 5 axis machining.
                            </span>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-black">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <div className=" lg:hidden flex">
                                <img alt="da" src={serimg1} />
                            </div>
                            <div className="hidden group cursor-pointer lg:block lg:bg-bgser1 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                                <div className=" group-hover:bg-opacity-20 w-full h-full bg-black bg-opacity-50"></div>
                            </div>
                            <Fade>
                                <div className="flex flex-col space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-white/40">Our Performances</span>
                                    <span className=" text-lg text-white font-txt-font">
                                        Choose SVPM for unparalleled accuracy and expertise. Experience cutting-edge 
                                        machining services in a state-of-the-art facility, 
                                        ensuring each project meets the highest standards of quality and precision
                                    </span>
                                    <div className=" font-txt-font text-white">
                                        <ul className=" space-y-8">
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-3xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                    <span className=" text-main-color">
                                                        Intevac                                                    
                                                    </span>
                                                </div>
                                                <span className=" text-white/50 ml-8 text-xl font-thin">
                                                    More than $20,000,000 contract provided precision machine custom
                                                    parts for their (PMR) Perpendicular Magnetic Recording Tool. Contract
                                                    started 2010 and still active client.
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-3xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                    <span className=" text-main-color">
                                                        Kateeva
                                                    </span>
                                                </div>
                                                <span className=" text-white/50 ml-8 text-xl font-thin">
                                                    $2,600,000 contract provided precision machine parts to their inkjet
                                                    technology for advance display
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-3xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">03</span>
                                                    <span className=" text-main-color">
                                                        Lam Research
                                                    </span>
                                                </div>
                                                <span className=" text-white/50 ml-8 text-xl font-thin">
                                                    $1,600,000 contract provided machine parts to their PLASMA
                                                    BEVEL ETCH AND DEPOSITION.     
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" bg-black">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" grid gap-8 lg:grid-cols-2">
                            <Fade>
                                <div className="flex flex-col space-y-8">
                                    <span style={{fontFamily: 'Redressed'}} className=" text-3xl text-white/40">Targeting</span>
                                    <div className=" flex flex-col space-y-4">
                                        <span className=" text-4xl font-header-font text-gray-400">
                                            Serving the medical, solar, semiconductor, engineering, and high-tech
                                        </span>
                                        <span style={{fontFamily: 'Calligraffitti'}} className="text-6xl text-main-color">
                                            Sectors
                                        </span>
                                    </div>
                                    <div className=" font-txt-font text-white">
                                        <ul className=" space-y-8">
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">01</span>
                                                    <span className="">
                                                        Our Mission                                             
                                                    </span>
                                                </div>
                                                <span className=" text-white/40 ml-8 text-lg font-thin">
                                                    To provide high-precision machining and exceptional quality through advanced CNC technology, serving the medical, solar, semiconductor, engineering, and high-tech industries with dedication and excellence                                                
                                                </span>
                                            </li>
                                            <li className=" flex flex-col">
                                                <div className=" flex space-x-2 text-2xl">
                                                    <span style={{fontFamily: 'Calligraffitti'}} className="">02</span>
                                                    <span className="">
                                                        Our Vision
                                                    </span>
                                                </div>
                                                <span className=" text-white/40 ml-8 text-lg font-thin">
                                                    Benefit from state-of-theTo be the leading provider of innovative machining solutions, setting the standard for quality, precision, and customer satisfaction in the high-tech manufacturing sector-art equipment and innovative techniques ensuring superior quality.
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Fade>
                            <div className=" lg:hidden flex">
                                <img alt="da" src={serimg2} />
                            </div>
                            <div className="hidden group cursor-pointer lg:block lg:bg-bgser2 lg:w-full lg:h-full lg:bg-center lg:bg-cover lg:bg-no-repeat">
                                <div className=" group-hover:bg-opacity-20 w-full h-full bg-black bg-opacity-50"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className=" bg-black">
                <div className=" p-5 pt-6 pb-8 lg:pt-12 lg:pb-24">
                    <div className="mx-auto space-y-6 lg:space-y-16 max-w-screen-xl">
                        <div className=" flex justify-center items-center text-center">
                            <h1 className=" text-white text-4xl font-header-font">Our Equiments</h1>
                        </div>
                        <div className="grid gap-4 lg:gap-8 lg:grid-cols-4">
                            {ToolsData.map((d) => (
                                <div key={d.id} className="max-w-lg bg-black lg:border-[2px] lg:hover:border-main-color border-white p-4 cursor-pointer shadow-xl rounded-lg ease-in-out transition-all duration-1000 lg:hover:scale-105">
                                    <div className="overflow-hidden">
                                        <img src={d.img} alt="#" className="w-full" />
                                    </div>
                                    <div className="text-center px-6 py-4 space-y-2">
                                        <h1 className="text-main-color text-2xl font-semibold">{d.name}</h1>
                                        {d.desc && (
                                            <div className=" flex text-xl justify-center">
                                                <span className=" text-center text-white ">
                                                    {d.desc}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            </div>
                    </div>
                </div>
            </section>
            
        </main>
    )
}