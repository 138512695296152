import img2 from "../img/ser11.png";
import serimg from "../img/ser4.png";
import ser3 from "../img/ser3.png";
import ser5 from "../img/ser5.png";
const ServicesData = [
    {
        id: 1,
        name: 'Precision CNC Engineering',
        desc: 'Our Precision CNC Engineering services offer expert fabrication of a wide range of materials, including Aluminum, Stainless Steel, Titanium, Copper, Brass, Plastics, and more. We ensure each component is crafted to exact specifications with unmatched precision.',
        img: img2,
    },
    {
        id: 2,
        name: 'Rapid Prototyping Services',
        desc: 'With our Rapid Prototyping Services, we cater to urgent needs by delivering high-quality parts in as little as two days. Our dedicated team and streamlined processes ensure quick turnaround times without compromising on quality.',
        img: serimg,
    },
    {
        id: 3,
        name: 'Large-Scale Manufacturing',
        desc: 'Large-Scale Manufacturing at Halcyon ensures cost-efficiency and reliability through our APQP and PPAP implementations. We guarantee the consistency and quality of your products, saving you money in the long term.',
        img: ser3,
    },
    {
        id: 4,
        name: 'Vendor Network Solutions',
        desc: 'Our Comprehensive Vendor Network Solutions provide a one-stop shop for all your manufacturing needs. We leverage a vast network of controlled vendors to deliver a seamless and integrated production experience.',
        img: ser5
    }
];

export default ServicesData;
