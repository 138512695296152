import { React } from "react"
import Logo from "../img/Logo.png";
import ServicesData from "./ServicesData";

export default function Footer() {
    return (
        <footer className="bg-black cursor-pointer">
            <div className="mx-auto max-w-screen-xl px-6 pb-6 pt-16 lg:px-8 lg:pt-24">
                <div className="grid lg:space-x-12 grid-cols-1 gap-12 lg:grid-cols-3">
                    <div className="grid justify-items-center">
                        <div>
                            {/* <div className=" flex items-center flex-col">
                                <h2 style={{ fontFamily: "Comforter Brush" }} className="flex md:hidden text-4xl italic tracking-wider text-main-color">SVP</h2>
                                <h2 className="flex md:hidden text-2xl italic font-txt-font font-thin tracking-wider text-white"></h2>
                            </div> */}
                            <img alt="#" className="md:w-44 w-32 md:flex" src={Logo} />
                        </div>

                    </div>
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 font-serif lg:col-span-2">
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">About Us</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                <li>
                                    <a href="/" className=" transition text-white hover:text-main-color">
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="/About" className=" transition text-white hover:text-main-color">
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a href="/Services" className=" transition text-white hover:text-main-color">
                                        Services
                                    </a>
                                </li>
                                <li>
                                    <a href="/Contact" className=" transition text-white hover:text-main-color">
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">Services</p>
                            <ul className="mt-8 space-y-4 text-sm">
                                {ServicesData.map((s) => (
                                    <li key={s.id}>
                                        <a target="_blank" href="/Services" className=" transition text-white hover:text-main-color">
                                            {s.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        
                        <div className="text-center sm:text-left">
                            <p className="text-lg font-medium text-main-color">Contact Us</p>
                            <ul className="mt-8 space-y-5 text-sm">
                                <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="tel:4084519042">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-8 w-8 shrink-0 text-main-color"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                                            />
                                        </svg>
                                        <span className="flex-1 text-white">(408) 451-9042</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end" href="mailto:info@svpmachine.com">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-8 w-8 shrink-0 text-main-color"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                        >
                                            <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                                            />
                                        </svg>
                                        <span className="flex-1 text-white">Info@svpmachine.com</span>
                                    </a>
                                </li>
                                <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-8 w-8 shrink-0 text-main-color"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                    </svg>
                                    <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/dir/36.1365504,-115.1696896/1641+Rogers+Ave,+San+Jose,+CA+95112/@36.594579,-121.1919643,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x808fcb8bac118117:0x941e3e16fab0b271!2m2!1d-121.905039!2d37.3701329?entry=ttu" className="-mt-0.5 flex-1 not-italic text-white">
                                        1641 Rogers Ave, San Jose, CA 95112                                    
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="mt-12 border-t border-gray-100 pt-6">
                    <div className="text-center sm:flex sm:justify-between sm:text-left">
                        <p className="text-sm text-white">
                            <span className="block sm:inline">All rights reserved.</span>
                        </p>

                        <p className="mt-4 text-sm text-white sm:order-first sm:mt-0">
                            &copy; 2024 SVP Machining
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};