import {React , useState , useEffect} from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {faArrowUp} from "@fortawesome/free-solid-svg-icons"
import Iso from '../img/Cer/Iso.png';
import Itar from '../img/Cer/itar.png';
import Cage from '../img/Cer/cage.png';


function FloatBtn(){
    const Data = [Iso , Itar , Cage]
    const [isThere , SetisThere] = useState(false)
    const changeisThere = () =>{
        if (window.pageYOffset > 300){
            SetisThere(true)
        }
        else{
            SetisThere(false)
        }
    }

    // const scrollTop = () => {
    //     window.scrollTo({
    //         top:0,
    //         behavior:"smooth",
    //     })
    // }

    useEffect(() => {
        window.addEventListener('scroll' , changeisThere)
        return () => {
            window.removeEventListener('scroll' , changeisThere)
        }
    } , [])

    return (
        <main>
            <div className= {`${isThere?"opacity-100": "opacity-0"} flex flex-col lg:space-y-2 fixed bottom-4 lg:bottom-12 z-10 right-4`}>
                {Data.map((s,index) => (
                    <div key={index} className= "rounded-full flex justify-center items-center p-2 shadow-lg">
                        <button className="cursor-pointer" type="button">
                            <img alt="#1" src={s} className="lg:h-14 lg:w-14 w-12 h-12" />
                        </button>
                    </div>
                ))}
            </div>
        </main>
    );
};

export default FloatBtn;