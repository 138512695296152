import React from "react";
import spapic from "../img/9.png"


export default function Contact(){
    return (
        <main>
            <section className=" bg-contsec bg-center bg-no-repeat bg-cover w-screen lg:h-[700px] relative">
                <div className=" w-full h-screen lg:h-full bg-black bg-opacity-80">
                    <div className=" flex lg:px-0 px-4 space-y-6 flex-col lg:max-w-screen-lg mx-auto h-full text-white justify-center items-center">
                        <h1 className=" text-main-color lg:text-6xl text-4xl font-header-font">Contact Us</h1>
                    </div>
                </div>
            </section>

            <section className=" bg-black">
                <div className="mx-auto md:max-w-screen-xl cursor-pointer text-white">
                    <div className="grid grid-cols-1 text-justify md:gap-8 lg:grid-cols-3 ">
                        <div className="col-span-2 md:w-full w-screen p-4 space-y-4 h-[400px] md:h-full lg:space-y-8 transition ease-in-out duration-200 delay-100 hover:opacity-50">
                            <img className=" h-full w-full" src={spapic} alt="" />
                        </div>
                        <div className="p-4 space-y-4 lg:space-y-8 lg:text-justify lg:grid place-items-center justify-center text-center">
                            <div className="">
                                <div className="grid grid-cols-1 p-4 space-y-5">
                                    <div>
                                        <span className="text-3xl text-main-color tracking-wider">See us in person</span>
                                    </div>
                                    <div className="space-y-3">
                                        <p className="font-newFont">We love our customers and welcome them to visit Monday through Friday.</p>
                                        <ul className="space-y-2 font-semibold">
                                            <li>
                                                <a className="transition text-white t-underline t-underline-black border-b-yellow-500" target="_blank"  rel="noreferrer" href="https://www.google.com/maps/dir/36.1365504,-115.1696896/1641+Rogers+Ave,+San+Jose,+CA+95112/@36.594579,-121.1919643,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x808fcb8bac118117:0x941e3e16fab0b271!2m2!1d-121.905039!2d37.3701329?entry=ttu">
                                                    Address: 1641 Rogers Ave San Jose, CA 95112
                                                </a>
                                            </li>
                                            <li>
                                                <a className="transition text-white t-underline t-underline-black border-b-yellow-500" href="tel:4084519042">
                                                    Phone#: (408) 451-9042
                                                </a>
                                            </li>
                                            <li>
                                                <a href="mailto:info@svpmachine.com" className="transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Email#: Info@svpmachine.com
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <hr></hr> */}
                                    {/* <div>
                                        <div className="font-semibold text-lg">Business Hours:</div>
                                        <ul className="space-y-4 text-sm mt-4">
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Mon: 10:00 am - 8:00 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Tues:  10:00 am - 8:00 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Wed:  10:00 am - 8:00 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Thu:  10:00 am - 8:00 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Fri:  10:00 am - 8:00 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Sat: 9:00 am - 7:00 pm
                                                </span>
                                            </li>
                                            <li>
                                                <span href="" className=" transition text-white t-underline t-underline-black border-b-yellow-500">
                                                    Sun: 12:00 pm - 6:00 pm
                                                </span>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid justify-items-center">
                    <iframe className="w-full md:px-12" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6341.665246511604!2d-121.9076139239025!3d37.37013713536519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcb8bb1f1dd8b%3A0x344d96b863d07fcc!2sSilicon%20Valley%20Precision%20Machine%20Company!5e0!3m2!1sen!2sus!4v1717858277229!5m2!1sen!2sus"  height="600" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </main>
    )
}